import React from "react";

interface IProps {
    onClick: () => void;
    isOpen: boolean;
}

const Hamburger = ({ onClick, isOpen }: IProps) => {
    return (
        <button
            aria-label="hamburger"
            type="button"
            onClick={onClick}
            className={`hamburger ${isOpen ? "active" : ""}`}
        >
            <div className="hamburger__lines">
                <span />
                <span />
                <span />
            </div>
            <div className="hamburger__cross">
                <span />
                <span />
            </div>
        </button>
    );
};

export default Hamburger;
