import React, { ReactNode } from "react";

interface IProps {
    children: ReactNode;
}

const TechnoList = ({ children }: IProps) => {
    return <ul className="technologies__list">{children}</ul>;
};

export default TechnoList;
