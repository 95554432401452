import React from "react";
import {ContactsUs} from "Templates/ContactsUs";
import {TextCase} from "Templates/TextContent";
import CaseTop from "Pages/Cases/View/Views/CaseTop";
import IProps from "./interface";
import {
    CaseOther,
    CaseImageGroup,
    CaseFlow,
    CasePhoneLine
} from "./Sections"

const CaseBarber = ({_data, view}: IProps) => {
    const data = _data[view];

    return (
        <div className="single-case">
            <CaseTop _data={_data} view={view}/>
            <CasePhoneLine/>
            <TextCase data={data.caseTextSecondary}/>
            <CaseFlow data={data.flow}/>
            <TextCase data={data.caseTextExperience}/>
            <CaseImageGroup data={data.previewImg}/>
            <TextCase data={data.caseTextThird}/>
            <CaseOther data={data.caseSlide}/>
            <ContactsUs/>
        </div>
    );
};

export default CaseBarber;