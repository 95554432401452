import React, { ReactNode } from "react";

const slideCss = {
    position: "relative",
} as React.CSSProperties;

interface IProps {
    children: ReactNode;
    styles?: Object;
}

const SliderItem = ({ children, styles = {} }: IProps) => {
    return (
        <div className="embla-slider__item" style={{ ...slideCss, ...styles }}>
            {children}
        </div>
    );
};

export default SliderItem;
