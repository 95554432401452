import React from "react";
import { Content, Container } from "Templates/Layout";
import { PageHead } from "Templates/PageHead";
import { CasesList } from "./Views";
import { ContactsUs } from "Templates/ContactsUs";
import { Helmet } from "react-helmet";

const Cases = () => {
    return (
        <>
            <Helmet>
                <title>Rocque: Cases</title>
                <meta content="We help our client to bring their incredible ideas to life." name="description" />
                <meta property="og:title" content="Rocque: Cases" />
                <meta property="og:description" content="We help our client to bring their incredible ideas to life." />
            </Helmet>

            <Content>
                <PageHead title={["Cases"]} text={["We help our client to bring their incredible ideas to life"]} />
                <div className="cases">
                    <Container>
                        <CasesList />
                        <div className="cases__more">And more...</div>
                    </Container>
                </div>
                <ContactsUs />
            </Content>
        </>
    );
};

export default Cases;
