import React from "react";

type Props = {
    src: string;
    alt: string;
    loading?: boolean;
    className?: string;
    style?: any;
};

const Img = ({ src, alt, loading = true, className = "", style }: Props) => (
    <img className={className} src={`/assets/images/${src}`} alt={alt} loading={loading ? "lazy" : "eager"} style={style} />
);

export default Img;
