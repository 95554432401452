import React, { ReactNode } from "react";

interface Iprops {
    size?: "small" | "normal";
    isBordered?: boolean;
    children?: ReactNode;
    src: string;
    onClick: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
    offset?: number;
}

const AnchorButton = ({ src, children, size = "normal", isBordered = false, onClick, offset = 60 }: Iprops) => {
    const _onClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        const anchor: HTMLElement | null = document.getElementById(src);
        if (anchor) {
            const offsetTop = anchor.getBoundingClientRect().top + window.pageYOffset;
            e.preventDefault();
            onClick(e);
            window.scroll({
                top: offsetTop - offset,
                // behavior: "smooth",
            });
        }
    };

    return (
        <a
            href={src}
            className={`button button_${isBordered ? "bordered" : "default"} size_${size}`}
            onClick={_onClick}
        >
            {children}
        </a>
    );
};

export default AnchorButton;
