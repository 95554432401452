import React, { createRef } from "react";
import {Container} from "Templates/Layout";
import {Heading3} from "Templates/Heading";
import {ScrollAnimationBlock} from "Templates/ScrollAnimationBlock"
import Parallax from "./Parallax";

interface IImages {
    img: string;
    alt: string;
    number: string;
}

type Info = {
    title: string;
}

interface IInfos {
    img: string;
    title: string[];
    info: Array<Info>;
}

interface IProps {
    data: Array<IInfos>;
    dataImages?: Array<IImages>;
}

const CaseList = ({data, dataImages}: IProps) => {
    const ref = createRef<HTMLDivElement>();

    return (
        <section className="single-case__section single-case__section_dark stack">
            <Parallax ref={ref}>
                <ScrollAnimationBlock className="single-case__bg" animate="fadeInUp" duration={2}>
                    {dataImages?.map(item => <img className={`single-case__bg-${item.number}`} src={`/assets/images/${item.img}.svg`} alt={item.alt} key={item.alt}/>)}
                </ScrollAnimationBlock>
            </Parallax>

            <Container>
                {data.map(item => (
                    <div className="single-case__row" key={`caseList-${item.title}`}>
                        <ScrollAnimationBlock className="single-case__column single-case__column-img" animate="fadeInUp" duration={1}>
                            <picture>
                                <source srcSet={`/assets/images/${item.img}-479.webp`} media="(max-width: 479px) "/>
                                <source srcSet={`/assets/images/${item.img}.webp`} media="(max-width: 1439px) "/>
                                <img className="single-case__img" src={`/assets/images/${item.img}.webp`} alt="Preview case list" loading="lazy"/>
                            </picture>
                        </ScrollAnimationBlock>
                        <div className="single-case__column">
                            <Heading3 lines={item.title}/>
                            <ul className="stack__list">
                                {item.info.map(elem => (
                                    <li className="stack__item" key={`CaseListText${elem.title}`}>{elem.title}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </Container>
        </section>
    );
};

export default CaseList;