import React, { ReactNode } from "react";

type Props = {
    children?: ReactNode;
};

const HeaderWrap = ({ children }: Props) => {
    return <div className="header__wrap">{children}</div>;
};

export default HeaderWrap;
