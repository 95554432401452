import React, {useState, createRef, useEffect} from "react";
import {Img} from "Templates/Img";

interface IItems {
    netbook: string;
    white: string;
    dark: string;
    cursor: string;
}

interface IProps {
    data: Array<IItems>
}

const CaseBlackWhite = ({data}: IProps) => {
    const [style, setStyle] = useState({
        left: '50%',
        widthD: '50%',
        widthW: '50%',
        widthL: '50%',
        widthR: '50%'
    });

    const ref = createRef<HTMLDivElement>();
    const ref_bg = createRef<HTMLDivElement>();

    const formatValue = (val: number) => val >= 0 ? val : 0;

    const handleMouseMove = (e: React.MouseEvent) => {
        const cursor = e.pageX,
              innerWidth = ref.current?.offsetWidth!,
              offsetLeft = ref.current?.offsetLeft!,
              cursorPosition = cursor - offsetLeft,
              innerWidthBg = ref_bg.current?.offsetWidth!;

        window.requestAnimationFrame(() => {
            setStyle( {
                left: `${cursorPosition}px`,
                widthW: `${formatValue(cursorPosition)}px`,
                widthD: `${formatValue(innerWidth - cursorPosition)}px`,
                widthL: `${formatValue(cursor)}px`,
                widthR: `${formatValue(innerWidthBg - cursor)}px`
            });
        });
    };

    const handleMouseLeave = () => {
        window.requestAnimationFrame(() => {
            setStyle( {
                left: '50%',
                widthD: '50%',
                widthW: '50%',
                widthL: '50%',
                widthR: '50%'
            });
        });
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        const cursor = Math.round(formatValue(e.touches[0].pageX)),
              innerWidth = ref.current?.offsetWidth!,
              offsetLeft = ref.current?.offsetLeft!,
              touchPosition = cursor - offsetLeft,
              innerWidthBg = ref_bg.current?.offsetWidth!;

        window.requestAnimationFrame(() => {
            setStyle( {
                left: `${touchPosition}px`,
                widthW: `${formatValue(touchPosition)}px`,
                widthD: `${formatValue(innerWidth - touchPosition)}px`,
                widthL: `${formatValue(cursor)}px`,
                widthR: `${formatValue(innerWidthBg - cursor)}px`
            });
        });
    };

    useEffect(() => {
        window.addEventListener("resize", handleMouseLeave);
        return () => {
            window.removeEventListener("resize", handleMouseLeave);
        };
    });

    return (
        <section className="single-case__section single-case__section_dark single-case__section_dark_white preview-case"
                 ref={ref_bg}
                 onMouseMove={handleMouseMove}
                 onMouseLeave={handleMouseLeave}
                 onTouchMove={handleTouchMove}>
            <div className="preview-case__left" style={{width: style.widthL}}/>
            {data.map(item => (
                <div className="preview-case__row preview-case__row_white" key={`blackAndWhite-${item.dark}`}>
                    <div className="preview-case__inner">
                        <Img src={item.netbook} alt="Preview MacBook"/>
                    </div>

                    <div className="preview-case__dark-white" ref={ref}>
                        <div className="preview-case__white"
                             style={{
                                 width: style.widthW,
                                 backgroundImage: `url(/assets/images/${item.dark})`
                             }}/>
                        <div className="preview-case__line_image"
                             style={{left: style.left}}>
                            <div className="preview-case__line_cursor">
                                <Img src={item.cursor} alt="Preview cursor"/>
                            </div>
                        </div>
                        <div className="preview-case__dark"
                             style={{
                                 width: style.widthD,
                                 backgroundImage: `url(/assets/images/${item.white})`
                             }}/>
                    </div>
                </div>
            ))}
            <div className="preview-case__right" style={{width: style.widthR}}/>
        </section>
    )
}

export default CaseBlackWhite;
