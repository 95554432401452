import React from "react";
import {Container} from "Templates/Layout";
import {Img} from "Templates/Img";
import {ScrollAnimationBlock} from "Templates/ScrollAnimationBlock";

type Items =  {
    img: string;
    alt: string;
    classRight?: string;
    classLeft?: string;
    classAnim: string;
}

interface IProps {
    data: Array<Items>;
}

const CaseImageGroup = ({data}: IProps) => {
    return (
        <section className="single-case__section single-case__section_dark preview-case">
            <Container>
                <div className="preview-case__wrap">
                    <div className="preview-case__row">
                        {data.map(item => (
                            <ScrollAnimationBlock className="preview-case__column preview-case__column_width" animate={item.classAnim}  key={`CasePreviewImageGroup${item.alt}`}>
                                <div className={`preview-case__block ${item.classRight ? item.classRight : ""} ${item.classLeft ? item.classLeft : ""}`}>
                                    <Img src={item.img} alt={item.alt}/>
                                </div>
                            </ScrollAnimationBlock>
                        ))}
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default CaseImageGroup;