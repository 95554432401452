import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AnchorButton } from "Templates/Button";

interface IProps {
    open: boolean;
    data: Array<any>;
    onClick: () => void;
}

const MobileNav = ({ open, data, onClick }: IProps) => {
    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
        }

        return () => {
            document.body.style.overflow = "unset";
        };
    }, [open]);

    return (
        <div className={`mobile-overlay ${open ? "active" : ""}`}>
            <div className="mobile-overlay__middle">
                <nav className="mobile-nav">
                    <ul className="mobile-nav__list">
                        {data.map((item) => (
                            <li key={`Mobile-Nav-${item.label}`} className="mobile-nav__item">
                                <NavLink to={item.path} className="mobile-nav__link" onClick={onClick} exact={item?.exact}>
                                    {item.label}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                    <AnchorButton src="contacts" onClick={onClick}>
                        Contact Us
                    </AnchorButton>
                </nav>
            </div>
        </div>
    );
};

export default MobileNav;
