import React from "react";
import {Container} from "Templates/Layout";

interface IInfos {
    title: string;
    description: string[];
}

type Items = {
    title: string;
    info: Array<IInfos>;
}

interface IProps {
    data: Array<Items>;
}

const CaseInfo = ({data}: IProps) => {
    return (
        <section className="single-case__section single-case__section_light case-text">
            <Container>
                {data.map(item => (
                    <div className="case-text__row" key={`caseInfo-${item.title}`}>
                        <div className="case-text__column">
                            <p>
                                {item.title}
                            </p>
                        </div>
                        <div className="case-text__column case-text__column_with-bg case-text__column_width">
                            <ul className="case-text__list">
                                {item.info.map(item => (
                                    <li className="case-text__item" key={`CaseInfoText${item.title}`}>
                                        <div className="case-text__list-title">{item.title}</div>
                                        {item.description.map(elem => (
                                            <div className="case-text__list-desc" key={`CaseInfoText${elem}`}>{elem}</div>
                                        ))}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </Container>
        </section>
    );
};

export default CaseInfo;