import React from "react";
import { Container } from "Templates/Layout";
import { Description } from "Templates/Content";
import TechnoRow from "./TechnoRow";
import TechoHead from "./TechnoHead";
import TechnoHeadCol from "./TechnoHeadCol";
import TechnoTitle from "./TechnoTitle";
import TechnoBody from "./TechnoBody";
import TechnoList from "./TechnoList";
import TechnoItem from "./TechnoItem";

interface IItems {
    icon: string;
    subtitle: string;
}

interface IObject {
    title: string;
    description: string[];
    items: Array<IItems>;
}

interface IProps {
    data: Array<IObject>;
}

const TechoContent = ({ data }: IProps) => {
    return (
        <section className="technologies">
            {data.map(({ title, description, items }, index) => (
                <TechnoRow key={`technologies-row-${title}-${index}`}>
                    <Container>
                        <TechoHead>
                            <TechnoHeadCol>
                                <TechnoTitle title={title} />
                            </TechnoHeadCol>
                            <TechnoHeadCol>
                                <Description data={description} />
                            </TechnoHeadCol>
                        </TechoHead>
                        <TechnoBody>
                            <TechnoList>
                                {items.map(({ icon, subtitle }, index) => (
                                    <TechnoItem
                                        key={`technologies-item-${icon}-${index}`}
                                        icon={icon}
                                        subtitle={subtitle}
                                    />
                                ))}
                            </TechnoList>
                        </TechnoBody>
                    </Container>
                </TechnoRow>
            ))}
        </section>
    );
};

export default TechoContent;
