import React from "react";

interface IMetaItem {
    data: object;
}

interface Idata {
    type?: string;
    href?: string;
    icon?: string;
    title?: string;
}

const checkData = ({ data }: IMetaItem) => {
    const { type, href, icon, title }: Idata = data;

    switch (type) {
        case "phone":
            return (
                <a href={`tel:${href}`} className="meta__link">
                    <span className={`meta__icon icon-${icon}`} />
                    <span>{title}</span>
                </a>
            );
        case "placeholder":
            return (
                <a href={href} target="_blanc" rel="noopener noreferrer" className="meta__link">
                    <span className={`meta__icon icon-${icon}`} />
                    <span>{title}</span>
                </a>
            );
        default:
            return (
                <a href={`mailto:${href}`} className="meta__link">
                    <span className={`meta__icon icon-${icon}`} />
                    <span>{title}</span>
                </a>
            );
    }
};

const MetaItem = ({ data }: IMetaItem) => {
    return <li className="meta__item">{checkData({ data })}</li>;
};

export default MetaItem;
