import React from "react";

interface IProps {
    title: string;
}

const TechnoTitle = ({ title }: IProps) => {
    return <h2 className="technologies__title">{title}</h2>;
};

export default TechnoTitle;
