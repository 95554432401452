import React, { ReactNode } from "react";

interface IProps {
    children: ReactNode;
}

const TechnoBody = ({ children }: IProps) => {
    return <div className="technologies__body">{children}</div>;
};

export default TechnoBody;
