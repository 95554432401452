import React, { ReactNode } from "react";

type Props = {
    children?: ReactNode;
};

const FormRow = ({ children }: Props) => {
    return <div className="form__row">{children}</div>;
};

export default FormRow;
