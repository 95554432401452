import React from "react";
import { Container } from "Templates/Layout";
import { Heading2 } from "Templates/Heading";
import { Description } from "Templates/Content";

type Props = {
    title: Array<string>;
    text?: Array<string>;
};

const PageHead = ({ title, text = [] }: Props) => {
    return (
        <Container>
            <section className="page-head">
                <div className="page-head__column">
                    <Heading2 lines={title} />
                </div>
                {text.length ? (
                    <div className="page-head__column">
                        <Description data={text} />
                    </div>
                ) : (
                    ""
                )}
            </section>
        </Container>
    );
};

export default PageHead;
