import React from "react";
import {Container} from "Templates/Layout";
import {Heading3} from "Templates/Heading";
import {Img} from "Templates/Img";

type Items = {
    title: string;
    icon: string;
}

interface IInfo {
    title: string;
    techno: Array<Items>;
}

interface IProps {
    data: Array<IInfo>;
}


const CaseUseTechno = ({data}: IProps) => {

    return (
        <section className="single-case__section single-case__section_light use-techno">
            <Container>
                {data.map(item => (
                    <div className="use-techno__wrap" key={`techno-${item.title}`}>
                        <div className="case-text__column case-text__column_centered">
                            <Heading3 lines={[item.title]}/>
                        </div>
                        <ul className="use-techno__list">
                            {item.techno.map(elem => (
                                <li className="use-techno__item" key={`CaseUseTechno${elem.title}`}>
                                    <div className="technologies__icon">
                                        <Img src={elem.icon} alt={"Technologies use"}/>
                                    </div>
                                    <p className="technologies__subtitle">
                                        {elem.title}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </Container>
        </section>
    );
};

export default CaseUseTechno;
