import React from "react";

export interface Props {
    onClick: () => void;
    title: string;
    isActive: boolean;
}

const ServicesControlList = ({ title, onClick, isActive }: Props) => {
    return (
        <li className="services-tabs__controls-item">
            <button
                onClick={onClick}
                type="button"
                className={`services-tabs__controls-btn ${isActive ? "active" : ""}`}
            >
                <span>{title}</span>
            </button>
        </li>
    );
};

export default ServicesControlList;
