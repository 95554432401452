import React, { useState, createRef, useEffect } from "react";
import { Container } from "Templates/Layout";
import { AnchorButton } from "Templates/Button";
import { Scroll } from "Templates/Scroll";
import { Description } from "Templates/Content";
import { Heading1 } from "Templates/Heading";
import { Animation } from "Templates/Animation";

interface IState {
    width: number;
    height: number;
}

const HomeIntro = () => {
    const ref = createRef<HTMLElement>();
    const [sizes, setSizes] = useState<IState>({
        width: 0,
        height: 0,
    });
    useEffect(() => {
        const intro = document.querySelector(".section.intro")!;

        const handleResize = () => {
            setSizes({
                width: intro.clientWidth,
                height: intro.clientHeight,
            });
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <section className="section intro" ref={ref}>
            <Animation width={sizes.width} height={sizes.height} />
            <Container>
                <div className="intro__wrap">
                    <Heading1 lines={["Rocque Your", "Software"]} />
                    <Description
                        data={[
                            "We are a leading software development company that provides a wide range of top-quality services for web and mobile platforms.",
                        ]}
                    />
                    <AnchorButton src="contacts" onClick={() => {}}>
                        Discover
                    </AnchorButton>
                </div>
                <Scroll />
            </Container>
        </section>
    );
};

export default HomeIntro;
