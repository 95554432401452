import React from "react";
import { Description } from "Templates/Content";
import { Link } from "react-router-dom";

export interface Props {
    img: string;
    title: string;
    subtitle: string;
    src: string;
    text?: string[];
}

const CaseSlide = ({ img, title, subtitle, src, text }: Props) => {
    return (
        <Link className="case-slide show-desc" to={`/cases/${src}`}>
            <picture className="case-slide__overlay">
                <source srcSet={`/assets/images/content/${img}-479.jpg`} media="(max-width: 479px) " />
                <source srcSet={`/assets/images/content/${img}-1439.jpg`} media="(max-width: 1439px) " />
                <img src={`/assets/images/content/${img}.jpg`} alt={title} loading="lazy" />
            </picture>
            <div className="case-slide__content">
                <h3 className="title title_h3 case-slide__title">{title}</h3>
                <div className="case-slide__subtitle">{subtitle}</div>
                <Description data={text || ['']} />
            </div>
            <div className="case-slide__button">
                <div className="button button_bordered size_normal">
                    View More
                </div>
            </div>
        </Link>
    );
};

export default CaseSlide;
