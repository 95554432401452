import React from "react";
import {Container} from "Templates/Layout";
import {Paragraph} from "Templates/Content";
import {Heading5} from "Templates/Heading";
import {BarberSvg} from "./PreviewSvg";

interface IProps {
    title: string;
    subtitle: string[];
    text: string;
    img?: string;
    imgPhone?: string;
}

const Preview = ({title, subtitle, text, img, imgPhone}: IProps) => {

    return (
        <section className="single-case__section preview">
            <div>
                <div className="parallax">
                    <div className="parallax__img parallax__img-barber"/>
                </div>
                <BarberSvg />
            </div>
            <Container>
                <div className="preview__row">
                    <div className="preview__column">
                        <h1 className="preview__title-h1">{title}</h1>
                        <Heading5 lines={subtitle}/>
                        <Paragraph>{text}</Paragraph>
                    </div>
                    <div className="preview__column">
                        <div className="preview__block">
                            <img className="preview__block-small preview__right animate__animated animate__delay-1s" src={`/assets/images/content/${imgPhone}.webp`} alt={title} loading="lazy"/>
                            <img className="preview__up" src={`/assets/images/content/${img}.webp`} alt={title} loading="lazy"/>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default Preview;
