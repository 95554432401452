import React, { ReactNode } from "react";

type Props = {
    children?: ReactNode;
    onSubmit: Function;
};

const Form = ({ children, onSubmit }: Props) => {
    const _onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit();
    };
    return (
        <form className="form" noValidate autoComplete="off" onSubmit={_onSubmit}>
            {children}
        </form>
    );
};

export default Form;
