import React from "react";
import { compose } from "ramda";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { LocationOverlay } from "Hoc";
import { Wrapper } from "Templates/Layout";
import Home from "Pages/Home/Index/index";
import Cases from "Pages/Cases/Index/index";
import SingleCase from "Pages/Cases/View/index";
import PrivacyPolicy from "Pages/PrivacyPolicy/Index/index";
import TermsConditions from "Pages/TermsConditions/Index/index";
import Technologies from "Pages/Technologies/Index/index";
import Error from "Pages/Error/Index/index";
import { ScrollToTop } from "Templates/ScrollToTop";
import { SizeListener } from "Hoc";

const App = () => (
    <Wrapper>
        <BrowserRouter>
            <LocationOverlay>
                <>
                    <ScrollToTop />
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/cases/" component={Cases} />
                        <Route path="/cases/:id" component={SingleCase} />
                        <Route path="/privacy-policy" component={PrivacyPolicy} />
                        <Route path="/terms-conditions" component={TermsConditions} />
                        <Route path="/technologies" component={Technologies} />
                        <Route path="*" component={Error} />
                    </Switch>
                </>
            </LocationOverlay>
        </BrowserRouter>
    </Wrapper>
);

export default compose(SizeListener)(App);
