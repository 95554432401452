import React, { ReactNode } from "react";

interface IProps {
    children: ReactNode;
}

const TechnoHeadCol = ({ children }: IProps) => {
    return <div className="technologies__head-col">{children}</div>;
};

export default TechnoHeadCol;
