import React, { ReactNode } from "react";

type Props = {
    children?: ReactNode;
};

const FooterBlock = ({ children }: Props) => {
    return <div className="footer__block">{children}</div>;
};

export default FooterBlock;
