import React from "react";
import {Img} from "Templates/Img";
// @ts-ignore
import Marquee from "react-double-marquee";

const CaseBarberPhoneLine = () => {
    return (
        <section className="single-case__section single-case__section_light phone-line">
            <div
                className="phone-line__content"
                style={{
                    width: "100%",
                    whiteSpace: "nowrap",
                }}
            >
                <Marquee speed={0.05} childMargin={0} delay={0} direction="right">
                    {[1, 2, 3, 4, 5, 6].map((i, index) => (
                        <span key={index} className="phone-line__item">
                            <Img src={`content/iphone-barber-${i}.png`} alt="phone-line__item" loading/>
                        </span>
                    ))}
                </Marquee>
            </div>
        </section>
    );
};

export default CaseBarberPhoneLine;