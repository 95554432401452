import React, { ReactNode } from "react";
// @ts-ignore
import ScrollAnimation from "react-animate-on-scroll";

interface IProps {
    children: ReactNode;
    animate: string;
    duration?: number;
    className?: string;
}

const ScrollAnimationBlock = ({children, animate, duration, className}: IProps) => {
    return (
        <ScrollAnimation className={className} animateIn={animate} animateOnce={true} duration={duration ? duration : 1}  >
            {children}
        </ScrollAnimation>
    )
}

export default ScrollAnimationBlock;