import React, { FormEvent, useState, useEffect } from "react";
import emailjs, { init } from "emailjs-com";
import { Container } from "Templates/Layout";
import { Meta } from "Templates/Meta";
import { Input, FormBlock, FormRow, Form, FormMessage } from "Templates/Form";
import { Button } from "Templates/Button";
import { Heading2 } from "Templates/Heading";
import meta from "./Meta.json";

init("user_OlwpxsrxMqHuvowZj2lHF");

interface State {
    name: string;
    phone: string;
    lastName: string;
    email: string;
    message: string;
    checkbox: boolean;
}

interface Message {
    messageStatus: boolean;
    showMessage: boolean;
}

interface FormData {
    toName: string;
    fromName: string;
    phone: string;
    lastName: string;
    email: string;
    message: string;
}

const ContactsUs = () => {
    const [formData, setFormData] = useState<State>({
        name: "",
        phone: "",
        lastName: "",
        email: "",
        message: "",
        checkbox: false,
    });
    const [disabled, setDisabled] = useState<boolean>(true);
    const [message, setMessage] = useState<Message>({
        messageStatus: false,
        showMessage: false,
    });
    const handleChangeValue = (event: FormEvent) => {
        const target = event.target as HTMLInputElement | HTMLTextAreaElement;
        setFormData({
            ...formData,
            [target.name]: target.value,
        });
    };
    const handleChangePhoneValue = (event: FormEvent) => {
        const target = event.target as HTMLInputElement;
        const regexp = /^[0-9]*$/;
        if (regexp.test(target.value)) {
            setFormData({
                ...formData,
                [target.name]: target.value,
            });
        }
    };
    const handleChangeCheckbox = () => {
        setFormData({
            ...formData,
            checkbox: !formData.checkbox,
        });
    };
    const handleShowMessage = () => {
        setMessage({
            ...message,
            showMessage: !message.showMessage,
        });
    };

    const _formData: FormData = {
        toName: "Rocque Admin",
        fromName: formData.name,
        phone: formData.phone,
        lastName: formData.lastName,
        email: formData.email,
        message: formData.message,
    };

    const handleSubmit = () => {
        setDisabled(true);

        emailjs.send("gmail", "template_AYoljRVP", _formData).then(
            (result) => {
                setMessage({
                    messageStatus: true,
                    showMessage: true,
                });
                setFormData({
                    name: "",
                    phone: "",
                    lastName: "",
                    email: "",
                    message: "",
                    checkbox: false,
                });
            },
            (error) => {
                console.warn(error.text);
                setMessage({
                    messageStatus: false,
                    showMessage: true,
                });
            },
        );
    };

    useEffect(() => {
        const { name, phone, lastName, email, message, checkbox } = formData;
        setDisabled(![name, phone, lastName, email, message, checkbox].every((i) => i));
    }, [formData]);

    return (
        <section id="contacts" className="section contacts">
            <Container>
                <Heading2 stroke lines={["Contact us"]} />

                {message.showMessage && <FormMessage response={message.messageStatus} onClick={handleShowMessage} />}

                <div className="contacts__wrap">
                    <div className="contacts__form">
                        <Form onSubmit={handleSubmit}>
                            <FormRow>
                                <FormBlock>
                                    <Input
                                        onChange={handleChangeValue}
                                        label="First Name"
                                        value={formData.name}
                                        id="name"
                                        name="name"
                                    />
                                </FormBlock>
                                <FormBlock>
                                    <Input
                                        onChange={handleChangeValue}
                                        label="Last Name"
                                        value={formData.lastName}
                                        id="lastName"
                                        name="lastName"
                                    />
                                </FormBlock>
                            </FormRow>
                            <FormRow>
                                <FormBlock>
                                    <Input
                                        type="number"
                                        onChange={handleChangePhoneValue}
                                        label="Phone number"
                                        value={formData.phone}
                                        id="phone"
                                        name="phone"
                                    />
                                </FormBlock>
                                <FormBlock>
                                    <Input
                                        type="email"
                                        onChange={handleChangeValue}
                                        label="E-mail"
                                        value={formData.email}
                                        id="email"
                                        name="email"
                                    />
                                </FormBlock>
                            </FormRow>
                            <FormRow>
                                <FormBlock>
                                    <Input
                                        type="textarea"
                                        onChange={handleChangeValue}
                                        label="Message"
                                        value={formData.message}
                                        id="message"
                                        name="message"
                                    />
                                </FormBlock>
                            </FormRow>
                            <FormRow>
                                <FormBlock>
                                    <Input
                                        type="checkbox"
                                        onChange={handleChangeCheckbox}
                                        label=""
                                        checked={formData.checkbox}
                                        id="checkbox"
                                        name="checkbox"
                                    />
                                </FormBlock>
                            </FormRow>
                            <FormRow>
                                <FormBlock>
                                    <Button name="submit-form" type="submit" disabled={disabled} onClick={() => {}}>
                                        Get contact
                                    </Button>
                                </FormBlock>
                            </FormRow>
                        </Form>
                    </div>
                    <div className="contacts__meta">
                        <Meta items={meta} />
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default ContactsUs;
