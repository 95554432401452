import React, { useEffect } from "react";
import { Icon } from "Templates/Icon";

interface IProps {
    response: boolean;
    onClick: () => void;
}

const FormMessage = ({ response, onClick }: IProps) => {
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            onClick();
        }, 3000);
        return () => clearTimeout(timeoutId);
    }, [onClick]);

    return (
        <div className="message">
            <div className="message__icon">
                <Icon name={response ? "success" : "unsuccess"} />
            </div>
            <div className="message__text">{response ? "Message successfully send!" : "Message was not send!"}</div>
        </div>
    );
};

export default FormMessage;
