import React from "react";
import { useBreakpoint } from "Hoc";
import { NavLink } from "react-router-dom";
import { Container } from "Templates/Layout";
import { CaseSlide } from "Templates/Cases";
import { Props as casesProps } from "Templates/Cases/CaseSlide";
import { HeadingRowWrap, Heading2 } from "Templates/Heading";
import { Slider, SliderList, SliderItem } from "Templates/Slider";
import { arrayRender } from "Services";
import cases from "../../../../Cases.json";

interface casesType extends casesProps {
    _id: string;
}

const json = {
    cases: arrayRender<casesType>(cases),
};

const HomeCases = () => {
    const breakpoint = useBreakpoint();
    const { xs, sm, md } = breakpoint;
    const checkPoints = [xs, sm, md].includes(true);

    const listCss = {
        marginLeft: "-3rem",
    };

    const slideCss = {
        minWidth: checkPoints ? "100%" : "50%",
        paddingLeft: "3rem",
    } as React.CSSProperties;

    return (
        <section className="section cases">
            <Container>
                <div className="cases__wrap">
                    <HeadingRowWrap>
                        <Heading2 gray lines={["Cases"]} />
                        {!xs && (
                            <NavLink to="/cases" className="button button_default size_small">
                                View All
                            </NavLink>
                        )}
                    </HeadingRowWrap>
                </div>

                <Slider controls dots>
                    <SliderList styles={listCss}>
                        {json.cases.map(({ _id, img, title, subtitle, src, text }) => (
                            <SliderItem key={_id} styles={slideCss}>
                                <CaseSlide img={img} title={title} subtitle={subtitle} src={src} text={text} />
                            </SliderItem>
                        ))}
                    </SliderList>
                </Slider>

                {xs && (
                    <div className="cases__bottom">
                        <NavLink to="/cases" className="button button_default size_normal">
                            View All
                        </NavLink>
                    </div>
                )}
            </Container>
        </section>
    );
};

export default HomeCases;
