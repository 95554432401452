import React, { Fragment } from "react";
import { useBreakpoint } from "Hoc";
import { arrayRender } from "Services";
import { Container } from "Templates/Layout";
import { PlanList, PlanItem } from "Templates/Plan";
import { Props as planProps } from "Templates/Plan/PlanItem";
import { Heading2 } from "Templates/Heading";
import { Slider, SliderList, SliderItem } from "Templates/Slider";
import * as json from "../../../../HomeContent.json";

const sliderCss = {
    margin: "0",
    padding: "0",
};

const slideCss = {
    minWidth: "100%",
} as React.CSSProperties;

interface planType extends planProps {
    _id: string;
}

const plan = arrayRender<planType>(json.plan);

interface IPlanProps {
    count: string;
    img: string;
    title: string;
    text: string;
    lg?: boolean;
    md?: boolean;
    sm?: boolean;
    xs?: boolean;
}

const getPlanItem = ({ count, img, title, text, lg, md, sm, xs }: IPlanProps) => {
    return <PlanItem count={count} img={img} title={title} text={text} lg={lg} md={md} sm={sm} xs={xs} />;
};

const HomePlan = () => {
    const breakpoint = useBreakpoint();
    const checkPoints = [breakpoint.xs, breakpoint.sm].includes(true);
    const { lg, md, sm, xs } = breakpoint;

    return (
        <section className="section plan">
            <Container>
                <Heading2 stroke lines={["How Does It Work"]} />
            </Container>
            <Container>
                <div className="plan__wrap">
                    {!checkPoints && (
                        <PlanList>
                            {plan.map(({ count, img, title, text, _id }) => (
                                <Fragment key={_id}>
                                    {getPlanItem({ count, img, title, text, lg, md, sm, xs })}
                                </Fragment>
                            ))}
                        </PlanList>
                    )}

                    {checkPoints && (
                        <PlanList>
                            <Slider styles={sliderCss} controls dots>
                                <SliderList>
                                    {plan.map(({ count, img, title, text, _id }) => (
                                        <SliderItem key={_id} styles={slideCss}>
                                            <Fragment key={_id}>
                                                {getPlanItem({ count, img, title, text, lg, md, sm, xs })}
                                            </Fragment>
                                        </SliderItem>
                                    ))}
                                </SliderList>
                            </Slider>
                        </PlanList>
                    )}
                </div>
            </Container>
        </section>
    );
};

export default HomePlan;
