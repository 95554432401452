(function () {
    interface IndexableWindow {
        [key: string]: any;
    }
    var lastTime: any = 0;
    var vendors = ["ms", "moz", "webkit", "o"];
    const win = window as IndexableWindow;
    for (var x = 0; x < vendors.length && !win.requestAnimationFrame; ++x) {
        win.requestAnimationFrame = win[vendors[x] + "RequestAnimationFrame"];
        win.cancelAnimationFrame =
            win[vendors[x] + "CancelAnimationFrame"] || win[vendors[x] + "CancelRequestAnimationFrame"];
    }

    if (!win.requestAnimationFrame)
        win.requestAnimationFrame = function (callback: any, element: any) {
            console.log("here");
            var currTime = new Date().getTime();
            var timeToCall = Math.max(0, 16 - (currTime - lastTime));
            var id = win.setTimeout(function () {
                callback(currTime + timeToCall);
            }, timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };

    if (!win.cancelAnimationFrame)
        win.cancelAnimationFrame = function (id: number) {
            clearTimeout(id);
        };
})();

export const start = () => {
    let canvas: any = document.getElementById("canvas")!;

    let ctx = canvas.getContext("2d");

    const gridStep = 50;

    let dots: any = new Map();
    let line: any = new Set();
    let visibleDots: any = new Map();

    let horDots = Math.floor(canvas.width / gridStep);
    let verDots = Math.floor(canvas.height / gridStep);

    const lineMax = 10;

    const lineWidth = 2;
    // const bgColor = " #121516";
    const lineColor = "#626262";
    const lineSpeed = 1;

    const randomInt = (min: number, max: number, float: boolean = false) => {
        let rand = min + Math.random() * (max + 1 - min);
        if (float) {
            return +rand.toFixed(2);
        }
        return Math.floor(rand);
    };

    async function randomLine(): Promise<any> {
        const i = randomInt(1, horDots);
        const j = randomInt(1, verDots);

        const vector: any = new Map<string, Function>([
            ["top", (x: number, y: number, l: number) => `${x}:${Math.max(1, y - l)}`],
            ["left", (x: number, y: number, l: number) => `${Math.max(1, x - l)}:${y}`],
            ["bottom", (x: number, y: number, l: number) => `${x}:${Math.min(verDots, y + l)}`],
            ["right", (x: number, y: number, l: number) => `${Math.min(horDots, x + l)}:${y}`],

            [
                "top_left",
                (x: number, y: number, l: number) => {
                    const r = Math.min(1, Math.min(x - l, y - l));

                    return `${Math.max(1, x - r)}:${Math.max(1, y - r)}`;
                },
            ],

            [
                "top_right",
                (x: number, y: number, l: number) => {
                    const r = Math.min(1, Math.min(x - l, y - l));

                    return `${Math.min(horDots, x + r)}:${Math.max(1, y - r)}`;
                },
            ],
            [
                "bottom_left",
                (x: number, y: number, l: number) => {
                    const r = Math.min(1, Math.min(x - l, y - l));

                    return `${Math.max(1, x - r)}:${Math.min(verDots, y + r)}`;
                },
            ],
            [
                "bottom_right",
                (x: number, y: number, l: number) => {
                    const r = Math.min(1, Math.min(x - l, y - l));
                    return `${Math.min(horDots, x + r)}:${Math.min(verDots, y + r)}`;
                },
            ],
        ]);

        if (i === 1) {
            vector.delete("top");
            vector.delete("top_left");
            vector.delete("top_right");
        } else if (i === horDots) {
            vector.delete("bottom");
            vector.delete("bottom_left");
            vector.delete("bottom_right");
        }

        if (j === 1) {
            vector.delete("left");
            vector.delete("top_left");
            vector.delete("bottom_left");
        } else if (j === verDots) {
            vector.delete("right");
            vector.delete("top_right");
            vector.delete("bottom_right");
        }

        const keys = Array.from(vector.keys());
        const ranVector = keys[randomInt(0, keys.length - 1)];

        const lng = randomInt(1, 4);

        const t = vector.get(ranVector)(i, j, lng);

        if (dots.has(`${i}:${j}`) && dots.has(t)) {
            const start = dots.get(`${i}:${j}`);
            const end = dots.get(t);
            const startX = start.x;
            const startY = start.y;
            const endX = end.x;
            const endY = end.y;
            const step = startX === endX ? Math.abs(startY - endY) : Math.abs(startX - endX);

            createDot(`${i}:${j}`, step);
            createDot(t, step);

            return {
                start,
                end,
                step,
            };
        } else {
            return randomLine();
        }
    }

    async function forceLine() {
        if (line.size >= lineMax) return;

        const { start, end, step } = await randomLine();

        line.add(drawLine(start, end, step));
    }

    function drawCircle(x: number, y: number, dotSize: number) {
        // Circle
        ctx.beginPath();
        ctx.arc(x, y, dotSize, 0, 2 * Math.PI, false);
        ctx.fillStyle = lineColor;
        ctx.fill();
        ctx.closePath();

        // Border around circle
        // ctx.beginPath();
        // ctx.arc(x, y, dotSize, 0, 2 * Math.PI, false);
        // ctx.lineWidth = 8;
        // ctx.strokeStyle = "#121516";
        // ctx.stroke();
        // ctx.closePath();
    }

    interface IDrawLine {
        x: number;
        y: number;
    }

    const drawLine = ({ x: startX, y: startY }: IDrawLine, { x: endX, y: endY }: IDrawLine, step: number) => {
        let count = 0;

        const goToHorizontal = () => horizontalLine(startX, startY, endX, endY, step, count);

        const goToVertical = () => verticalLine(startX, startY, endX, endY, step, count);

        const goToLeftTop = () => diagonalToLeftTop(startX, startY, endX, endY, step, count);

        const goToRightTop = () => diagonalToRightTop(startX, startY, endX, endY, step, count);

        const goToLeftBottom = () => diagonalToLeftBottom(startX, startY, endX, endY, step, count);

        const goToRightBottom = () => diagonalToRightBottom(startX, startY, endX, endY, step, count);

        const go = (call: Function) => () => {
            count += lineSpeed;

            if (count < step * 2) {
                ctx.beginPath();
                call();
                ctx.closePath();
                return true;
            }

            call = () => ({});

            return false;
        };

        let draw: Function = () => ({});

        if (startX === endX) {
            draw = goToVertical;
        } else if (startY === endY) {
            draw = goToHorizontal;
        } else if (startY > endY && startX > endX) {
            draw = goToLeftTop;
        } else if (startY > endY && startX < endX) {
            draw = goToRightTop;
        } else if (startY < endY && startX > endX) {
            draw = goToLeftBottom;
        } else if (startY < endY && startX < endX) {
            draw = goToRightBottom;
        }

        return {
            update: go(draw),
        };
    };

    function drawDotsGrid() {
        for (let i = 1; i <= horDots; i++) {
            for (let j = 1; j <= verDots; j++) {
                dots.set(`${i}:${j}`, { x: gridStep * i, y: gridStep * j });
            }
        }
    }

    drawDotsGrid();

    function clear() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    }

    async function loop() {
        window.requestAnimationFrame(() => {
            clear();
            forceLine();

            for (const item of line) {
                const t = item.update();
                if (!t) {
                    line.delete(item);
                }
            }

            for (const [key, item] of visibleDots) {
                const t = item.update();
                if (!t) {
                    visibleDots.delete(key);
                }
            }
            loop();
        });
    }

    loop();

    function createDot(key: string, step: number) {
        if (visibleDots.has(key)) {
            const dotSave = visibleDots.get(key);
            dotSave.reset();
            return dotSave;
        }

        let _dotSize = 1;
        const maxSize = 6;
        const delay = step / gridStep + randomInt(1, 2, true);
        const dot = {
            ...dots.get(key),
            size: maxSize / 100,
        };

        const render = {
            reset: () => {
                dot.size = 0;
            },
            update: () => {
                _dotSize += maxSize / 100;
                if (dot.size < 1) {
                    dot.size = 0;
                }

                if (_dotSize < maxSize) {
                    dot.size = _dotSize;
                } else if (_dotSize >= maxSize * delay) {
                    dot.size = maxSize - (_dotSize - maxSize * delay);
                } else if (_dotSize >= maxSize) {
                    dot.size = maxSize;
                }

                if (dot.size < 0.5) {
                    return false;
                }

                drawCircle(dot.x, dot.y, dot.size);

                return true;
            },
        };

        visibleDots.set(key, render);
        return render;
    }

    function lineIncrease(startX: number, startY: number, lineToX: number, lineToY: number) {
        ctx.moveTo(startX, startY);
        ctx.lineWidth = lineWidth;
        ctx.lineCap = "round";
        ctx.lineTo(lineToX, lineToY);
        ctx.stroke();
        ctx.strokeStyle = lineColor;
    }

    function lineDecrease(endX: number, endY: number, lineToX: number, lineToY: number) {
        ctx.moveTo(endX, endY);
        ctx.lineWidth = lineWidth;
        ctx.lineCap = "round";
        ctx.lineTo(lineToX, lineToY);
        ctx.stroke();
        ctx.strokeStyle = lineColor;
    }

    function horizontalLine(startX: number, startY: number, endX: number, endY: number, step: number, count: number) {
        if (startX < endX) {
            // Horizontal line from left to right
            if (count <= step) {
                lineIncrease(startX, startY, startX + count, startY);
            } else if (count <= step * 2) {
                lineDecrease(endX, endY, startX + (count - step), startY);
            }
        } else {
            // Horizontal line from right to left
            if (count <= step) {
                lineIncrease(startX, startY, startX - count, startY);
            } else if (count <= step * 2) {
                lineDecrease(endX, endY, startX - (count - step), startY);
            }
        }
    }

    function verticalLine(startX: number, startY: number, endX: number, endY: number, step: number, count: number) {
        if (startY < endY) {
            // Vertical line from top to bottom
            if (count <= step) {
                lineIncrease(startX, startY, startX, startY + count);
            } else if (count <= step * 2) {
                lineDecrease(endX, endY, startX, startY + (count - step));
            }
        } else {
            // Vertical lie from bottom to top
            if (count <= step) {
                lineIncrease(startX, startY, startX, startY - count);
            } else if (count <= step * 2) {
                lineDecrease(endX, endY, startX, startY - (count - step));
            }
        }
    }

    function diagonalToLeftTop(
        startX: number,
        startY: number,
        endX: number,
        endY: number,
        step: number,
        count: number,
    ) {
        // Diagonal line to left-top
        if (count <= step) {
            lineIncrease(startX, startY, startX - count, startY - count);
        } else if (count <= step * 2) {
            lineDecrease(endX, endY, startX - (count - step), startY - (count - step));
        }
    }

    function diagonalToRightTop(
        startX: number,
        startY: number,
        endX: number,
        endY: number,
        step: number,
        count: number,
    ) {
        // Diagonal line to right-top
        if (count <= step) {
            lineIncrease(startX, startY, startX + count, startY - count);
        } else if (count <= step * 2) {
            lineDecrease(endX, endY, startX + (count - step), startY - (count - step));
        }
    }

    function diagonalToLeftBottom(
        startX: number,
        startY: number,
        endX: number,
        endY: number,
        step: number,
        count: number,
    ) {
        // Diagonal line to left-bottom
        if (count <= step) {
            lineIncrease(startX, startY, startX - count, startY + count);
        } else if (count <= step * 2) {
            lineDecrease(endX, endY, startX - (count - step), startY + (count - step));
        }
    }

    function diagonalToRightBottom(
        startX: number,
        startY: number,
        endX: number,
        endY: number,
        step: number,
        count: number,
    ) {
        // Diagonal line to right-bottom
        if (count <= step) {
            lineIncrease(startX, startY, startX + count, startY + count);
        } else if (count <= step * 2) {
            lineDecrease(endX, endY, startX + (count - step), startY + (count - step));
        }
    }

    return {
        delete: () => {
            return (
                (horDots = Math.floor(canvas.width / gridStep)),
                (verDots = Math.floor(canvas.height / gridStep)),
                drawDotsGrid()
            );
        },
    };
};
