import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

function ScrollToTop({ history }: RouteComponentProps): null {
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        };
    }, [history]);

    return null;
}

export default withRouter(ScrollToTop);
