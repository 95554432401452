import React from "react";
import { NavLink } from "react-router-dom";

type Props = {
    data: Array<any>;
};

const TopNav = ({ data }: Props) => {
    return (
        <div className="top-nav">
            <ul className="top-nav__list">
                {data.map((item) => (
                    <li key={`TopNav${item.label}`} className="top-nav__item">
                        <NavLink to={item.path} className="top-nav__link" exact={item?.exact}>
                            {item.label}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TopNav;
