import React from "react";
import MetaItem from "./MetaItem";

interface IMeta {
    items: Array<object>;
}

const Meta = ({ items }: IMeta) => {
    return (
        <ul className="meta">
            {items.map((item, index) => {
                return <MetaItem key={`MetaItemKey-${index}`} data={item} />;
            })}
        </ul>
    );
};

export default Meta;
