import React, { Component } from "react";

interface IProps {
    type?: string;
    value?: string;
    checked?: boolean;
    name: string;
    id: string;
    disabled?: boolean;
    label?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

interface IState {
    isFocus: boolean;
}

class Input extends Component<IProps, IState> {
    state = {
        isFocus: false,
    };

    handleFocus = (): void => {
        this.setState({
            isFocus: true,
        });
    };

    handleBlur = (): void => {
        this.setState({
            isFocus: false,
        });
    };

    handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { onChange } = this.props;

        onChange(e);
    };

    render() {
        const { type = "text", value = "", checked = false, name, id, disabled = false, label, onChange } = this.props;
        const { isFocus } = this.state;
        const isShrink = !!value;

        const _shrink = [isFocus, isShrink].includes(true);

        switch (type) {
            case "textarea":
                return (
                    <div className={`form__input-wrap form__input-wrap_textarea ${_shrink ? "is-focus" : ""}`}>
                        {label && (
                            <label className="form__label" htmlFor={id}>
                                {label}
                            </label>
                        )}
                        <textarea
                            aria-invalid={false}
                            name={name}
                            id={id}
                            value={value}
                            onChange={this.handleChange}
                            disabled={disabled}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            className="form__input form__input_textarea"
                            rows={4}
                            autoComplete={type}
                        ></textarea>
                    </div>
                );
            case "checkbox":
                return (
                    <div className="checkbox">
                        <label className="checkbox__label" htmlFor={id}>
                            <input
                                id={id}
                                type="checkbox"
                                className="visuallyhidden"
                                checked={checked}
                                onChange={onChange}
                            />
                            <span className="checkbox__content">
                                <span className="checkbox__text">
                                    I agree with{" "}
                                    <a href="/terms-conditions" target="_blank" rel="noopener noreferrer">
                                        Terms & Conditions
                                    </a>
                                </span>
                            </span>
                        </label>
                    </div>
                );
            case "number":
                return (
                    <div className={`form__input-wrap ${_shrink ? "is-focus" : ""}`}>
                        {label && (
                            <label className="form__label" htmlFor={id}>
                                {label}
                            </label>
                        )}
                        <input
                            aria-invalid={false}
                            type="tel"
                            inputMode="numeric"
                            className="form__input"
                            value={value}
                            onChange={this.handleChange}
                            name={name}
                            id={id}
                            disabled={disabled}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            autoComplete={type}
                        />
                    </div>
                );
            default:
                return (
                    <div className={`form__input-wrap ${_shrink ? "is-focus" : ""}`}>
                        {label && (
                            <label className="form__label" htmlFor={id}>
                                {label}
                            </label>
                        )}
                        <input
                            aria-invalid={false}
                            type={type}
                            className="form__input"
                            value={value}
                            onChange={this.handleChange}
                            name={name}
                            id={id}
                            disabled={disabled}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            autoComplete={type}
                        />
                    </div>
                );
        }
    }
}

export default Input;
