import React from "react";
import { NavLink } from "react-router-dom";
import { Img } from "Templates/Img";

type Props = {
    src?: string;
    loading?: boolean;
};

const Logo = ({ src = "logo.svg", loading = false }: Props) => {
    return (
        <div className="logo">
            <NavLink to="/">
                <Img src={src} alt="logo" loading={loading} />
            </NavLink>
        </div>
    );
};

export default Logo;
