import React from "react";

export interface Props {
    img: string;
    title: string;
    text: Array<string>;
    isActive?: boolean;
}

const ServicesTabsItem = ({ img, title, text, isActive = false }: Props) => {
    return (
        <div className={`services-tabs__content-item ${isActive ? "active" : ""}`}>
            <div
                className="services-tabs__content-overlay"
                style={{ backgroundImage: `url(/assets/images/content/${img}.jpg)` }}
            />
            <div className="services-tabs__content-details">
                <h3 className="title title_h3 services-tabs__content-title">
                    <span>{title}</span>
                </h3>
                {text.map((i) => (
                    <p key={i}>
                        <span>{i}</span>
                    </p>
                ))}
            </div>
        </div>
    );
};

export default ServicesTabsItem;
