import React from "react";
import {Container} from "Templates/Layout";
import {ScrollAnimationBlock} from "Templates/ScrollAnimationBlock"

interface IItems {
    video: string;
    img: string;
    alt: string;
    browser: string;
}

interface IProps {
    data: Array<IItems>;
}

const CaseVideo = ({data}: IProps) => {
    return (
        <section className="single-case__section single-case__section_dark preview-case">
            <Container>
                <div className="preview-case__wrap">
                    <div className="preview-case__row">
                        {data.map(item => (
                            <ScrollAnimationBlock className="preview__video" animate="fadeInUp" key={`CaseVideo-${item.alt}`}>
                                <img className="preview__video_img" src={`${item.img}.webp`} alt={item.alt} loading="lazy"/>
                                <div className="preview__video_block">
                                    <img className="preview__video_top" src={`${item.browser}.png`} alt="Preview browser top"/>
                                    <video className="preview__video-v" autoPlay={true} loop={true} muted={true}>
                                        <source src={`${item.video}.webm`} type="video/webm"/>
                                        <source src={`${item.video}.mp4`} type="video/mp4"/>
                                    </video>
                                </div>
                            </ScrollAnimationBlock>
                        ))}
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default CaseVideo;