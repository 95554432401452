import React, { ReactNode } from "react";

type Props = {
    children: ReactNode;
};

const HeadingRowWrap = ({ children }: Props) => {
    return <div className="title-wrap">{children}</div>;
};

export default HeadingRowWrap;
