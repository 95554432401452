import React, { ReactNode } from "react";

type Props = {
    children?: ReactNode;
};

const FormBlock = ({ children }: Props) => {
    return <div className="form__block">{children}</div>;
};

export default FormBlock;
