import React from "react";
import { Container } from "Templates/Layout";
import { Clutch } from "Templates/Clutch";
import { Logo } from "Templates/Logo";
import { BottomNav, FooterWrap, FooterRow, FooterBlock, Copyright } from "./Items";
import * as json from "../MenuItems.json";

interface IProps {
    isDark?: boolean;
}

const Footer = ({ isDark }: IProps) => {
    return (
        <footer className="footer" data-theme={isDark ? "dark" : "light"}>
            <Container>
                <FooterWrap>
                    <FooterRow>
                        <FooterBlock>{isDark ? <Logo src="logo.svg" /> : <Logo src="logo-dark.svg" />}</FooterBlock>
                        <FooterBlock>
                            <BottomNav data={json.nav} />
                        </FooterBlock>
                    </FooterRow>
                    <FooterRow>
                        <FooterBlock>
                            <Copyright />
                        </FooterBlock>
                        <FooterBlock>
                            <Clutch rate={5} />
                        </FooterBlock>
                    </FooterRow>
                </FooterWrap>
            </Container>
        </footer>
    );
};

export default Footer;
