import React, { ReactNode, ReactElement } from "react";

interface Props {
    children: ReactNode;
}

const ServicesTabs = ({ children }: Props): ReactElement => {
    return <ul className="services-tabs__controls-list">{children}</ul>;
};

export default ServicesTabs;
