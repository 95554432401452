import React from "react";
import { Content, Container } from "Templates/Layout";
import { Heading2 } from "Templates/Heading";

const Error = () => {
    return (
        <Content>
            <Container>
                <div className="error-page">
                    <Heading2 stroke lines={["Page not found"]} />
                </div>
            </Container>
        </Content>
    );
};

export default Error;
