import React from "react";
// @ts-ignore
import Marquee from "react-double-marquee";

interface Tech {
    speed: number;
    items: string[];
    direction?: string;
}

const TechnoLine = ({ speed, items, direction = "right" }: Tech) => {
    return (
        <div
            className="techno__line"
            style={{
                width: "100%",
                whiteSpace: "nowrap",
            }}
        >
            <Marquee speed={speed} childMargin={0} delay={0} direction={direction}>
                {items.map((i, index) => (
                    <span key={`key-${i}-${index}`} className="techno__item">
                        {i}
                    </span>
                ))}
            </Marquee>
        </div>
    );
};

export default TechnoLine;
