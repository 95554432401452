import React, { ReactNode } from "react";

const containerCss = {
    display: "flex",
};

interface IProps {
    children: ReactNode;
    styles?: Object;
}

const SliderList = ({ children, styles = {} }: IProps) => {
    return (
        <div className="embla-slider__list" style={{ ...containerCss, ...styles }}>
            {children}
        </div>
    );
};

export default SliderList;
