import React, { useState, useEffect } from "react";
import { Container } from "Templates/Layout";
import { AnchorButton } from "Templates/Button";
import { HeaderWrap, TopNav } from "./Items";
import { Logo } from "Templates/Logo";
import { Hamburger } from "Templates/Hamburger";
import { MobileNav } from "Templates/MobileNav";
import * as json from "../MenuItems.json";

const Header = () => {
    const [sticky, setSticky] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const handleSetSticky = () => {
        setSticky(window.scrollY !== 0);
    };
    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleSetSticky);

        return () => {
            window.removeEventListener("scroll", handleSetSticky);
        };
    }, []);

    return (
        <header className={`header ${sticky ? "header_sticky" : ""}`}>
            <Container>
                <HeaderWrap>
                    <Logo />
                    <TopNav data={json.nav} />
                    <AnchorButton size="small" isBordered src="contacts" onClick={() => {}} >
                        Contact Us
                    </AnchorButton>
                    <Hamburger isOpen={open} onClick={() => handleClick()} />
                    <MobileNav open={open} data={json.nav} onClick={() => handleClick()} />
                </HeaderWrap>
            </Container>
        </header>
    );
};

export default Header;
