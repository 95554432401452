import React from "react";
import { BreakpointProvider } from "Hoc";

const queries = {
    xs: "screen and (max-width: 479px)",
    sm: "screen and (min-width: 480px) and (max-width: 767px)",
    md: "screen and (min-width: 768px) and (max-width: 1023px)",
    lg: "screen and (min-width: 1024px) and (max-width: 1279px)",
    xl: "screen and (min-width: 1280px) and (max-width: 1439px)",
    xxl: "screen and (min-width: 1440px) and (max-width: 1679px)",
    xxxl: "screen and (min-width: 1680px)",
};

const SizeListener = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => ({ ...props }) => {
    return (
        <BreakpointProvider queries={queries}>
            <Component {...(props as P)} />
        </BreakpointProvider>
    );
};

export default SizeListener;
