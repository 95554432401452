import React, { useState, useEffect } from "react";

const Scroll = () => {
    const [hide, setHide] = useState<boolean>(false);
    const handleSetHide = () => {
        setHide(window.scrollY > 200);
        if (hide) {
            window.removeEventListener("scroll", handleSetHide);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleSetHide);

        return () => {
            window.removeEventListener("scroll", handleSetHide);
        };
    }, [hide]); /*Поправить [hide]*/

    return <div className={`mouse-scroll ${hide ? "is-hidden" : ""}`} />;
};

export default Scroll;
