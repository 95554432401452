import React, { ReactNode } from "react";

type Props = {
    children?: ReactNode;
};

const FooterWrap = ({ children }: Props) => {
    return <div className="footer__wrap">{children}</div>;
};

export default FooterWrap;
