import React from "react";
import { NavLink } from "react-router-dom";

type Props = {
    data: Array<any>;
};

const BottomNav = ({ data }: Props) => {
    return (
        <div className="bottom-nav">
            <ul className="bottom-nav__list">
                {data.map((item) => (
                    <li key={`BottomNav${item.label}`} className="bottom-nav__item">
                        <NavLink to={item.path} className="bottom-nav__link" exact={item?.exact}>
                            {item.label}
                        </NavLink>
                    </li>
                ))}
                <li className="bottom-nav__item">
                    <NavLink to="/privacy-policy" className="bottom-nav__link" exact>
                        Privacy Policy
                    </NavLink>
                </li>
                <li className="bottom-nav__item">
                    <NavLink to="/terms-conditions" className="bottom-nav__link" exact>
                        Terms & Conditions
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};

export default BottomNav;
