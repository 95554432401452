import React, {createRef} from "react";
import {Img} from "Templates/Img";
import {Container} from "Templates/Layout";
import {ScrollAnimationBlock} from "Templates/ScrollAnimationBlock";
import Parallax from "./Parallax";

interface IItems {
    name: string;
    classNumber: string;
    img: string;
    alt: string;
    duration: number;
}

interface IProps {
    img: string;
    classBg?: string;
    classImg?: string;
    classNone?: string;
    data?: Array<IItems>,
}

const CaseImage = ({img, classBg, classImg, classNone, data}: IProps) => {
    const ref = createRef<HTMLDivElement>();

    return (
        <section
            className={`single-case__section single-case__section_${classBg ? classBg : "dark"} preview-case ${classImg ? classImg : ""} preview-case`}>
            <Parallax ref={ref}>
                {data?.map((item) => (
                        <div className={`parallax__images parallax__${item.name} parallax__${item.classNumber}`} key={item.alt}>
                            <ScrollAnimationBlock animate="fadeInUp" duration={item.duration}>
                                <img src={`/assets/images/content/${item.img}`} alt={item.alt}/>
                            </ScrollAnimationBlock>
                        </div>
                    )
                )}
            </Parallax>
            <Container>
                <div className="preview-case__wrap">
                    <div className="preview-case__row preview-case__row_centered">
                        <ScrollAnimationBlock className="preview-case__column preview-case__column_no-margin" animate="fadeInUp">
                            <Img className={`preview-case__image ${classNone ? classNone : ""}`} src={img} alt="Preview 1"/>
                        </ScrollAnimationBlock>
                    </div>
                </div>
            </Container>
        </section>
    )
};


export default CaseImage;