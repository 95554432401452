import React, {useEffect, useState, forwardRef} from "react";
import {debounce} from "Services/Debounce";

type DivProps = React.HTMLProps<HTMLDivElement>

const Parallax = forwardRef<HTMLDivElement, DivProps>(({children}, ref:any) => {
    const [parallax, setParallax] = useState(0);

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const _handleScroll = () => {
         const scrollY = -ref.current?.getBoundingClientRect().y!;
         const userScroll = Math.max(scrollY, 0);

        if(userScroll >= 0 && userScroll <= ref.current.offsetHeight) {
            window.requestAnimationFrame(() => {
                setParallax(Math.round((userScroll / 4) - 30));
            });
        }
    };

    const _handleScrollMobile = () => {};

    const handleScroll = isMobile ? _handleScrollMobile : _handleScroll;

    debounce(handleScroll)

    useEffect(() => {
         window.addEventListener('scroll', handleScroll);
         return () => window.removeEventListener('scroll', handleScroll);
    });

    return(
        <div className="parallax" ref={ref} onScroll={handleScroll}>
            <div className="parallax__inner" style={{transform: `translate3d(0, ${parallax}px, 0)`}}>
                {children}
            </div>
        </div>
    )
});

export default Parallax;