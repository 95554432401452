import { EventEmitter } from "events";
import { debounce } from "Services";

const emitter = new EventEmitter();

window.addEventListener(
    "resize",
    debounce(() => emitter.emit("handleResize"), 0),
);

export { emitter };
