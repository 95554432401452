import React, { useState } from "react";
import { useBreakpoint } from "Hoc";
import { arrayRender } from "Services";
import { Container } from "Templates/Layout";
import { ServicesControlList, ServicesTabsItem, ServicesTabs } from "Templates/ServicesTabs";
import { Props as servicesProps } from "Templates/ServicesTabs/ServicesTabsItem";
import { Heading2 } from "Templates/Heading";
import { Slider, SliderList, SliderItem } from "Templates/Slider";
import * as _json from "Pages/HomeContent.json";

const sliderCss = {
    margin: "0",
    padding: "0",
};

const slideCss = {
    minWidth: "100%",
} as React.CSSProperties;
interface servicesType extends servicesProps {
    _id: string;
}

const json = {
    services: arrayRender<servicesType>(_json.services),
};

const HomeServices = () => {
    const breakpoint = useBreakpoint();
    const { xs, sm, md } = breakpoint;
    const [active, setActive] = useState<number>(0);
    const checkPoints = [xs, sm, md].includes(true);

    return (
        <section className="section services">
            <Container>
                <Heading2 stroke lines={["Services"]} />
            </Container>
            <div className="services__wrap">
                {!checkPoints && (
                    <Container>
                        <div className="services-tabs">
                            <div className="services-tabs__controls">
                                <ServicesTabs>
                                    {json.services.map(({ title, _id }, index) => (
                                        <ServicesControlList
                                            isActive={index === active}
                                            key={_id}
                                            title={title}
                                            onClick={() => setActive(index)}
                                        />
                                    ))}
                                </ServicesTabs>
                            </div>
                            <div className="services-tabs__content">
                                {json.services.map(({ img, title, text, _id }, index) => (
                                    <ServicesTabsItem
                                        isActive={index === active}
                                        key={_id}
                                        img={img}
                                        title={title}
                                        text={text}
                                    />
                                ))}
                            </div>
                        </div>
                    </Container>
                )}

                {checkPoints && (
                    <div className="services-tabs">
                        <div className="services-tabs__content">
                            <Slider styles={sliderCss} controls dots>
                                <SliderList>
                                    {json.services.map(({ img, title, text, _id }, index) => (
                                        <SliderItem key={_id} styles={slideCss}>
                                            <ServicesTabsItem
                                                isActive={index === active}
                                                img={img}
                                                title={title}
                                                text={text}
                                            />
                                        </SliderItem>
                                    ))}
                                </SliderList>
                            </Slider>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default HomeServices;
