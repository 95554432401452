import React, { ReactNode } from "react";

type Props = {
    name: string;
    type?: "submit" | "reset" | "button";
    disabled?: boolean;
    onClick: () => void;
    size?: "small" | "normal";
    isBordered?: boolean;
    children?: ReactNode;
};

const Button = ({ name, type = "button", disabled, onClick, size = "normal", isBordered = false, children }: Props) => {
    return (
        <button
            aria-label={name}
            type={type}
            disabled={disabled}
            onClick={onClick}
            className={`button button_${isBordered ? "bordered" : "default"} size_${size}`}
        >
            {children}
        </button>
    );
};

export default Button;
