import React, { ReactNode } from "react";

type Props = {
    children?: ReactNode;
};

const Wrapper = ({ children }: Props) => {
    return <div className="wrapper">{children}</div>;
};

export default Wrapper;
