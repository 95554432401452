import React from "react";

interface IProps {
    name: string;
    className?: string;
}

const Icon = ({ name, className = "" }: IProps) => <i className={`icon-${name} ${className}`} />;

export default Icon;
