import React from "react";
import { Container } from "Templates/Layout";
import { TechnoLine } from "Templates/TechnoLine";
import { Heading2 } from "Templates/Heading";
import * as json from "../../../../HomeContent.json";

const HomeTechno = () => {
    return (
        <section className="section techno">
            <Container>
                <Heading2 gray lines={["Technologies"]} />
            </Container>
            <div className="techno__wrap">
                <TechnoLine speed={0.03} items={json.technoLineOne} />
                <TechnoLine speed={0.04} items={json.technoLineTwo} direction="left" />
                <TechnoLine speed={0.055} items={json.technoLineThree} />
            </div>
        </section>
    );
};

export default HomeTechno;
