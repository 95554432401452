import React from "react";
import { Content } from "Templates/Layout";
import { PageHead } from "Templates/PageHead";
import { ContactsUs } from "Templates/ContactsUs";
import { TechoContent } from "./Views";
import technologies from "./Technologies.json";
import { Helmet } from "react-helmet";

const Technologies = () => {
    return (
        <>
            <Helmet>
                <title>Rocque: Technologies</title>
                <meta content="We use top technologies to solve our clients' business challenges." name="description" />
                <meta property="og:title" content="Rocque: Technologies" />
                <meta
                    property="og:description"
                    content="We use top technologies to solve our clients' business challenges."
                />
            </Helmet>

            <Content>
                <PageHead title={["Technologies"]} />
                <TechoContent data={technologies} />
                <ContactsUs />
            </Content>
        </>
    );
};

export default Technologies;
