import React, { ReactNode } from "react";

type Props = {
    children?: ReactNode;
};

const PlanList = ({ children }: Props) => {
    return <div className="plan__list">{children}</div>;
};

export default PlanList;
