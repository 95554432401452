import React from 'react';
import { TextCase } from 'Templates/TextContent';
import { Helmet } from 'react-helmet';
import { cond } from 'ramda';
import IProps from './interface';
import { CaseUseTechno, CaseList, CaseInfo, Preview, PreviewVideo } from './Sections';

const CaseTop = ({ _data, view }: IProps) => {
    const data = _data[view];

    const preview = cond([
        [
            (view) => ['home-barber'].includes(view),
            () => (
                <Preview
                    title={data.topTitle}
                    subtitle={[data.topSubtitle]}
                    text={data.topText}
                    img={data.topImg}
                    imgPhone={data.topImgSecondary}/>
            ),
        ],
        [
            (view) => ['solar-pay', 'rock-erp', 'kore', 'delivery-ware'].includes(view),
            () => (
                <PreviewVideo
                    view={view}
                    title={data.topTitle}
                    subtitle={[data.topSubtitle]}
                    text={data.topText}
                    data={data.top}
                    dataImages={data.topImg}/>
            ),
        ],
    ]);

    return (
        <>
            <Helmet>
                <title>{data.titleBrowser}</title>
                <meta content="We use top technologies to solve our clients' business challenges." name="description" />
                <meta property="og:title" content="Rocque: Technologies" />
                <meta
                    property="og:description"
                    content="We use top technologies to solve our clients' business challenges."
                />
            </Helmet>

            {preview(view)}
            <CaseInfo data={data.caseInfo} />
            <CaseList data={data.caseList} dataImages={data.caseListBgImg}/>
            <CaseUseTechno data={data.caseTechno} />
            <TextCase data={data.caseTextFirst} />
        </>
    );
};

export default CaseTop;
