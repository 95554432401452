import React from "react";
import { Container } from "Templates/Layout";
import { Heading4 } from "Templates/Heading";

type Item = {
    title: string[];
    text: string[];
};

type Props = {
    data: Array<Item>;
};

const TextContent = ({ data }: Props) => {
    return (
        <section className="text-content">
            <Container>
                {data.map(({ title, text }, index) => (
                    <div key={`TextRow${index}`} className="text-content__row">
                        <div className="text-content__column">{title.length ? <Heading4 lines={title} /> : ""}</div>
                        <div className="text-content__column">
                            {text.map((i) => (
                                <p key={i}>{i}</p>
                            ))}
                        </div>
                    </div>
                ))}
            </Container>
        </section>
    );
};

export default TextContent;
