import React, { useState, useEffect, useRef } from "react";
import { start } from "./utils";

interface IProps {
    width: number;
    height: number;
}

const Animation = ({ width, height }: IProps) => {
    const [run, setRun] = useState<boolean>(false);
    let _start: any;
    const startRef = useRef(_start);

    useEffect(() => {
        setRun(true);
        if (run) {
            startRef.current = start();
        }
    }, [run]);

    useEffect(() => {
        if (run) {
            startRef.current.delete();
        }
    }, [width, height]); /*Поправить [width, height]*/

    return <canvas id="canvas" className="canvas" width={width} height={height} />;
};

export default Animation;
