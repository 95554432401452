import React from "react";

type Props = {
    lines: Array<string>;
};

const Heading1 = ({ lines }: Props) => {
    return (
        <h1 className="title title_h1">
            {lines.map((i) => (
                <span key={i} className="title__line">
                    {i}
                </span>
            ))}
        </h1>
    );
};

export default Heading1;
