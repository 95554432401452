import React from "react";
import {ContactsUs} from "Templates/ContactsUs";
import {TextCase} from "Templates/TextContent";
import CaseTop from "Pages/Cases/View/Views/CaseTop";
import IProps from "./interface";
import {
    CaseOther,
    CaseImage
} from "./Sections"

const CaseSolarPay = ({_data, view}: IProps) => {
    const data = _data[view];

    return (
        <div className="single-case">
            <CaseTop _data={_data} view={view}/>
            <CaseImage img={data.previewImgFirst}
                       data={data.previewImgBgImgFirst}/>
            <TextCase data={data.caseTextSecondary}/>
            <CaseImage img={data.previewImgSecondary}
                       classImg={data.previewImgClass}
                       classNone={data.previewImgNone}/>
            <TextCase data={data.caseTextExperience}/>
            <CaseOther data={data.caseSlide}/>
            <ContactsUs/>
        </div>
    );
};

export default CaseSolarPay;