import React, { ReactNode } from "react";

type Props = {
    children?: ReactNode;
};

const FooterRow = ({ children }: Props) => {
    return <div className="footer__row">{children}</div>;
};

export default FooterRow;
