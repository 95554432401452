import React from "react";
import { Content } from "Templates/Layout";
import HomeIntro from "./Views/Sections/HomeIntro";
import HomeServices from "./Views/Sections/HomeServices";
import HomePlan from "./Views/Sections/HomePlan";
import HomeCases from "./Views/Sections/HomeCases";
import HomeTechno from "./Views/Sections/HomeTechno";
import HomeContacts from "./Views/Sections/HomeContacts";
import { Helmet } from "react-helmet";

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Rocque: Custom software development company</title>
                <meta
                    content="Rocque is a full-cycle software development company of talented and dedicated professionals called to create sophisticated web and mobile products."
                    name="description"
                />
                <meta property="og:title" content="Rocque: Custom software development company" />
                <meta
                    property="og:description"
                    content="Rocque is a full-cycle software development company of talented and dedicated professionals called to create sophisticated web and mobile products."
                />
            </Helmet>

            <Content>
                <HomeIntro />
                <HomeServices />
                <HomePlan />
                <HomeCases />
                <HomeTechno />
                <HomeContacts />
            </Content>
        </>
    );
};

export default Home;
