import React from "react";
import { arrayRender } from "Services";
import { CaseSlide } from "Templates/Cases";
import { Props as casesProps } from "Templates/Cases/CaseSlide";
import cases from "../../../Cases.json";

interface casesType extends casesProps {
    _id: string;
}

const json = {
    cases: arrayRender<casesType>(cases),
};

const CasesList = () => {
    return (
        <ul className="cases__list">
            {json.cases.map(({ _id, img, title, subtitle, src, text }) => (
                <li key={_id} className="cases__item">
                    <CaseSlide img={img} title={title} subtitle={subtitle} src={src} text={text} />
                </li>
            ))}
        </ul>
    );
};

export default CasesList;
