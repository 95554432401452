import React from "react";
import {Heading3} from "Templates/Heading";
import {Container} from "Templates/Layout";
import {CaseSlide} from "Templates/Cases";

interface IItems {
    img: string;
    title: string;
    subtitle: string;
    src: string;
    text: string[];
}

interface IProps {
    data: Array<IItems>
}

const CaseOther = ({data}: IProps) => {
    return (
        <section className="single-case__section single-case__section_gray case-other">
            <Container>
                <div className="case-other__title">
                    <Heading3 lines={["Other cases"]} isGray={true}/>
                </div>
                <div className="cases__list">
                    {data.map(item => (
                        <div className="cases__item" key={`CaseOther${item.title}`}>
                            <CaseSlide img={item.img} title={item.title} subtitle={item.subtitle} src={item.src} text={item.text && item.text}/>
                        </div>
                    ))}
                </div>
            </Container>
        </section>
    );
};

export default CaseOther;