import React from "react";
import {Heading3} from "Templates/Heading";
import {Container} from "Templates/Layout";

type Items = {
    title?: string[];
    text: string;
    classPadding?: string;
}

interface IProps {
    data: Array<Items>;
}

const TextCase = ({data}: IProps) => {
    return (
        <section className="single-case__section single-case__section_light case-text">
            <Container>
                {data.map(item => (
                    <div className={`case-text__column case-text__column_centered case-text__column_width ${item.classPadding ? item.classPadding : ""}`} key={`TextCase-${item.title}`}>
                        {item.title && <Heading3 lines={item.title}/>}
                        <p>
                            {item.text}
                        </p>
                    </div>
                ))}

            </Container>
        </section>

    );
};

export default TextCase;