import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../App/View/Header/index";
import Footer from "../App/View/Footer/index";

type Props = {
    children: React.ReactElement;
};

const LocationOverlay = ({ children }: Props) => {
    const { pathname } = useLocation();
    const dark = [""];

    return (
        <>
            <Header />
            {children}
            <Footer isDark={dark.includes(pathname)} />
        </>
    );
};
export default LocationOverlay;
