import React, {createRef} from "react";
import {Container} from "Templates/Layout";
import {Paragraph} from "Templates/Content";
import {Heading5} from "Templates/Heading";
import {cond} from "ramda";
import {DeliverySvg, KoreSvg, RockSvg, SolarSvg} from "./PreviewSvg";
import Parallax from "./Parallax";

interface IImages {
    img: string;
    alt: string;
    class: string;
    classNumber: string;
    classDelay: string;
}

interface IItems {
    img: string;
    src: string;
    browser: string;
    classBlock?: string;
}

interface IIProps {
    title: string;
    subtitle: string[];
    text: string;
    classBefore?: string;
    view: string;
    data: Array<IItems>;
    dataImages?: Array<IImages>
}

const PreviewVideo = ({title, subtitle, text, data, dataImages, view}: IIProps) => {
    const ref = createRef<HTMLDivElement>();

    const preview = cond([
        [
            (view) => ['solar-pay'].includes(view),
            () => <SolarSvg />,
        ],
        [
            (view) => ['kore'].includes(view),
            () => <KoreSvg />,
        ],
        [
            (view) => ['rock-erp'].includes(view),
            () => (
                <>
                    <div className="parallax__img parallax__img-rock"/>
                    <RockSvg />
                </>
            )

        ],
        [
            (view) => ['delivery-ware'].includes(view),
            () => <DeliverySvg />,
        ]
    ]);

    return (
        <section className="single-case__section preview">
            {preview(view)}
            <Parallax ref={ref}>
                {dataImages?.map(item => (
                    <div className={`parallax__images parallax__${item.class}-bg parallax__${item.class}${item.classNumber} animate__animated animate__fadeInUp ${item.classDelay}`}
                         key={item.img}>
                        <img src={`/assets/images/${item.img}`}
                             srcSet={`/assets/images/${item.img} 1x`}
                             alt={`${item.alt}`}/>
                    </div>
                ))}
            </Parallax>
            <Container>
                <div className="preview__row preview__row-center">
                    <div className="preview__column preview__column-width">
                        <h1 className="preview__title-h1">{title}</h1>
                        <Heading5 lines={subtitle}/>
                        <Paragraph>{text}</Paragraph>
                    </div>
                    {data.map(item => (
                        <div className="preview__column preview__video" key={item.img}>
                            <img className="preview__video_img" src={`/assets/images/${item.img}.webp`} alt="Top preview video" loading="lazy"/>
                            <div className={`preview__video_block ${item.classBlock ? item.classBlock : ""}`}>
                                <img className="preview__video_top" src={`${item.browser}.png`} alt="Preview browser top"/>
                                <video className="preview__video-v" autoPlay={true} loop={true} muted={true}>
                                    <source src={`${item.src}.webm`} type="video/webm"/>
                                    <source src={`${item.src}.mp4`} type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </section>
    );
}

export default PreviewVideo;