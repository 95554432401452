import React from "react";

type Props = {
    lines: Array<string>;
    stroke?: boolean;
    gray?: boolean;
};

const Heading4 = ({ lines, stroke = false, gray = false }: Props) => {
    return (
        <h4 className={`title title_h4 ${stroke ? "title_stroke" : ""}`}>
            {lines.map((i) => (
                <span key={i} className={`title__line ${gray ? "gray_text" : ""}`}>
                    {i}
                </span>
            ))}
        </h4>
    );
};

export default Heading4;
