import React from "react";

type Props = {
    data: Array<string>;
};

const Description = ({ data }: Props) => {
    return (
        <div className="description">
            {data.map((i) => (
                <p key={i}>{i}</p>
            ))}
        </div>
    );
};

export default Description;
