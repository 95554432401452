import React, { Component, createRef } from "react";

export interface Props {
    count: string;
    img: string;
    title: string;
    text: string;
    xxxl?: boolean;
    xxl?: boolean;
    xl?: boolean;
    lg?: boolean;
    md?: boolean;
    sm?: boolean;
    xs?: boolean;
}

interface State {
    height: number;
    isHover: boolean;
    cardWidth: number;
    cardHeight: number;
    rX: number;
    rY: number;
    bgX: number;
    bgY: number;
    timerId: number;
}

class PlanItem extends Component<Props, State> {
    state = {
        height: 0,
        isHover: false,
        cardWidth: 0,
        cardHeight: 0,
        rX: 0,
        rY: 0,
        bgX: 0,
        bgY: 0,
        timerId: 0,
    };

    private rootRef = createRef<HTMLDivElement>();
    private childRef = createRef<HTMLDivElement>();

    componentDidMount(): void {
        this.setState({
            height: this.childRef.current!.scrollHeight,
            cardWidth: this.rootRef.current!.offsetWidth,
            cardHeight: this.rootRef.current!.offsetHeight,
        });
    }

    handleUpdateState = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                height: this.childRef.current!.scrollHeight,
                cardWidth: this.rootRef.current!.offsetWidth,
                cardHeight: this.rootRef.current!.offsetHeight,
                isHover: false,
                rX: 0,
                rY: 0,
                bgX: 0,
                bgY: 0,
            };
        });
    };

    shouldComponentUpdate(nextProps: Props) {
        if (this.props !== nextProps) {
            const timerId = window.setTimeout(this.handleUpdateState, 500);
            this.setState((prevState) => ({
                ...prevState,
                timerId,
            }));
        }
        return true;
    }

    componentWillUnmount() {
        const { timerId } = this.state;
        clearTimeout(timerId);
    }

    handleHover = async () => {
        this.setState({
            isHover: true,
        });
    };

    handleMouseMove = async (e: React.MouseEvent) => {
        const { cardWidth, cardHeight } = this.state;
        const getCoords = (elem: HTMLElement) => {
            const box = elem.getBoundingClientRect();
            return {
                top: box.top + window.pageYOffset,
                left: box.left + window.pageXOffset,
            };
        };

        const mouseX = e.pageX - getCoords(this.rootRef.current!).left - this.state.cardWidth / 2;
        const mouseY = e.pageY - getCoords(this.rootRef.current!).top - this.state.cardHeight / 2;

        window.requestAnimationFrame(() => {
            this.setState({
                rX: Math.round((mouseX / cardWidth) * 30),
                rY: Math.round((mouseY / cardHeight) * -30),
                bgX: Math.round((mouseX / cardWidth) * -40),
                bgY: Math.round((mouseY / cardHeight) * -40),
            });
        });
    };

    handleMouseLeave = async () => {
        window.requestAnimationFrame(() => {
            this.setState(
                {
                    rX: 0,
                    rY: 0,
                    bgX: 0,
                    bgY: 0,
                },
                () => {
                    this.setState({
                        isHover: false,
                    });
                },
            );
        });
    };

    render() {
        const { count, img, title, text, lg, md, sm, xs } = this.props;
        const { height, isHover, rX, rY, bgX, bgY } = this.state;
        const checkPoints = [lg, md, sm, xs].includes(true);

        return (
            <>
                <div
                    className="plan__item"
                    ref={this.rootRef}
                    onMouseMove={!checkPoints ? this.handleMouseMove : () => {}}
                    onMouseEnter={!checkPoints ? this.handleHover : () => {}}
                    onMouseLeave={!checkPoints ? this.handleMouseLeave : () => {}}
                >
                    <div className="plan-step" style={{ transform: `rotateY(${rX}deg) rotateX(${rY}deg)` }}>
                        <span className="plan-step-count">{count}</span>
                        <div
                            className="plan-step-overlay"
                            style={{
                                backgroundImage: `url(/assets/images/content/${img}.jpg)`,
                                transform: `translateY(${bgX}px) translateX(${bgY}px)`,
                            }}
                        />
                        <h3 className="title title_h3 plan-step__title">{title}</h3>
                        <div className="plan-step__desc" ref={this.childRef} style={{ height: isHover ? height : 0 }}>
                            {text}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PlanItem;
