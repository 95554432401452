import React from "react";
import {Container} from "Templates/Layout";
import {Heading3} from "Templates/Heading";
import {Img} from "Templates/Img";
import {ScrollAnimationBlock} from "Templates/ScrollAnimationBlock";

type Items = {
    imgFirst: string;
    imgSecondary: string;
    imgThird: string;
    alt: string;
    icon: string;
    text: string;
    classOrder: string;
    backgroundVector: string;
    classVector: string;
}

interface IInfo {
    title: string[];
    info: Array<Items>;
}

interface IProps {
    data: Array<IInfo>;
}

const CaseFlow = ({data}: IProps) => {
    return (
        <section className="single-case__section single-case__section_dark flow">
            <Container>
                {data.map(item => (
                    <div className="flow__wrap" key={`flow-${item.title}`}>
                        <Heading3 lines={item.title}/>
                        {item.info.map(elem => (
                            <div className="flow__row" key={`CaseFlow${elem.text}`}>
                                <div className="flow__column">
                                    <div className="flow__block">
                                        <ScrollAnimationBlock animate="fadeInLeft flow__block-img" duration={2}>
                                            <Img src={elem.imgFirst} alt={elem.alt}/>
                                        </ScrollAnimationBlock>
                                        <ScrollAnimationBlock animate="fadeInLeft flow__block-img" duration={1.5}>
                                            <Img src={elem.imgSecondary} alt={elem.alt}/>
                                        </ScrollAnimationBlock>
                                        <ScrollAnimationBlock animate="fadeInLeft flow__block-img" duration={1}>
                                            <Img src={elem.imgThird} alt={elem.alt}/>
                                        </ScrollAnimationBlock>
                                    </div>
                                </div>

                                <div className={`flow__column flow__column_order ${elem.classOrder}`}>
                                    <ScrollAnimationBlock animate="fadeInUp">
                                        <div className="flow__img">
                                            <span className={`flow__img-icon icon-${elem.icon}`}/>
                                        </div>
                                        <div className="flow__title">
                                            {elem.text}
                                        </div>
                                    </ScrollAnimationBlock>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </Container>
        </section>
    );
};

export default CaseFlow;