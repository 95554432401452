import React from "react";
import {ContactsUs} from "Templates/ContactsUs";
import {TextCase} from "Templates/TextContent";
import CaseTop from "Pages/Cases/View/Views/CaseTop";
import IProps from "./interface";
import {
    CaseImage,
    CaseOther
} from "./Sections"

const CaseKore = ({_data, view}: IProps) => {
    const data = _data[view];

    return (
        <div className="single-case">
            <CaseTop _data={_data} view={view}/>
            <TextCase data={data.caseTextSecondary}/>
            <CaseImage img={data.previewImage} classImg={data.previewImgClass} classNone={data.previewImgNone}/>
            <TextCase data={data.caseTextExperience}/>
            <CaseOther data={data.caseSlide}/>
            <ContactsUs/>
        </div>
    );
};

export default CaseKore;