import React from "react";
import { Content } from "Templates/Layout";
import { PageHead } from "Templates/PageHead";
import { TextContent } from "Templates/TextContent";
import { ContactsUs } from "Templates/ContactsUs";
import terms from "./TermsConditions.json";
import { Helmet } from "react-helmet";

const TermsConditions = () => {
    return (
        <>
            <Helmet>
                <title>Rocque: Terms & Conditions</title>
                <meta
                    content="Terms and Conditions describes how and why we process your personal data when you access our website."
                    name="description"
                />
                <meta property="og:title" content="Rocque: Terms & Conditions" />
                <meta
                    property="og:description"
                    content="Terms and Conditions describes how and why we process your personal data when you access our website."
                />
            </Helmet>
            <Content>
                <PageHead
                    title={["Terms and", "conditions"]}
                    text={[
                        "Terms and Conditions  describes how and why we process your personal data when you access our website",
                    ]}
                />
                <TextContent data={terms} />
                <ContactsUs />
            </Content>
        </>
    );
};

export default TermsConditions;
