import React, { useState, useEffect } from "react";
import json from "./SingleCase.json";
import {Content} from "Templates/Layout";
import {
    CaseBarber,
    CaseSolarPay,
    CaseRockErp,
    CaseKore,
    CaseDeliveryWare
} from "./Views";

type Id = {
    id: string;
};

interface IMatch {
    isExact: boolean;
    params: Id;
    path: string;
    url: string;
}

interface IProps {
    match: IMatch;
}

const SingleCase = ({ match }: IProps) => {
    const [view, setView] = useState<string>("");

    useEffect(() => {
        setView(match.params.id);
    }, [match]);

    return (
        <Content>
            {view === "home-barber" && <CaseBarber _data={json} view={view} />}
            {view === "solar-pay" && <CaseSolarPay _data={json} view={view}/>}
            {view === "rock-erp" && <CaseRockErp _data={json} view={view}/>}
            {view === "kore" && <CaseKore _data={json} view={view}/>}
            {view === "delivery-ware" && <CaseDeliveryWare _data={json} view={view}/>}
        </Content>
    )
};

export default SingleCase;
