import React from "react";
import { Content } from "Templates/Layout";
import { PageHead } from "Templates/PageHead";
import { TextContent } from "Templates/TextContent";
import { ContactsUs } from "Templates/ContactsUs";
import privacy from "./PrivacyPolicy.json";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Rocque: Privacy Policy</title>
                <meta
                    content="Policies and practices regarding collection and use of your personal data."
                    name="description"
                />
                <meta property="og:title" content="Rocque: Privacy Policy" />
                <meta
                    property="og:description"
                    content="Policies and practices regarding collection and use of your personal data."
                />
            </Helmet>

            <Content>
                <PageHead
                    title={["Privacy", "Policy"]}
                    text={["Policies and practices regarding collection and use of your personal data"]}
                />
                <TextContent data={privacy} />
                <ContactsUs />
            </Content>
        </>
    );
};

export default PrivacyPolicy;
