import React from "react";
import { Img } from "Templates/Img";

interface IProps {
    icon: string;
    subtitle: string;
}

const TechnoItem = ({ icon, subtitle }: IProps) => {
    return (
        <li className="technologies__item">
            <div className="technologies__icon">
                <Img src={`/icons/${icon}.svg`} alt={icon} loading />
            </div>
            <div className="technologies__subtitle">{subtitle}</div>
        </li>
    );
};

export default TechnoItem;
