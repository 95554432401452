import React from "react";

type Props = {
    lines: Array<string>;
    stroke?: boolean;
    isGray?: boolean;
};

const Heading3 = ({ lines, stroke = false, isGray = false }: Props) => {
    return (
        <h3 className={`title title_h3 ${stroke ? "title_stroke" : ""}`}>

            {lines.map((i) => (
                <span key={i} className={`title__line ${isGray ? "gray_text" : ""}`}>
                    {i}
                </span>
            ))}
        </h3>
    );
};

export default Heading3;
