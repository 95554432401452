import React from "react";

const BarberSvg = () => {
    return (
        <svg className="preview__bg" viewBox="0 0 1507 869" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
            <path d="M-2 0H1509L1507 744.13C1507 744.13 1477.75 687.745 1181.22 692.19C952.368 695.621 934.38 808.055 767.49 822.039C596.603 822.039 463.691 744.13 217.854 744.13C3.68089 744.13 -2 855 -2 855L-2 0Z" fill="url(#paint0_linear)"/>
            <path d="M-2 0H1509L1507 767C1507 767 1478.73 710.549 1182 715C953 718.435 935 831 768 845C596.999 845 463.999 767 217.999 767C3.18079 767 -2 878 -2 878V0Z" fill="url(#paint1_linear)" fillOpacity="0.5"/>
            <defs>
                <linearGradient id="paint0_linear" x1="20.9845" y1="30.9639" x2="1428.74" y2="781.679" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B721B"/>
                    <stop offset="1" stopColor="#715822"/>
                </linearGradient>
                <linearGradient id="paint1_linear" x1="21" y1="52.9998" x2="1430" y2="804" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B68E3A"/>
                    <stop offset="1" stopColor="#9E7826"/>
                </linearGradient>
            </defs>
        </svg>
    )
};

const SolarSvg = () => {
    return (
        <svg className="preview__bg" viewBox="0 0 1482 856" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
            <path d="M0 0H1482V856C1482 856 1338.76 597.831 1042 598C746 598.169 0 856 0 856V0Z" fill="url(#paint0_linear)"/>
            <defs>
                <linearGradient id="paint0_linear" x1="23" y1="31" x2="1432" y2="782" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C12D48"/>
                    <stop offset="1" stopColor="#6718FA"/>
                </linearGradient>
            </defs>
        </svg>
    )
};

const KoreSvg = () => {
    return (
        <svg className="preview__bg" viewBox="0 0 1441 831" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
            <path d="M-25 -25H1457V831C1457 831 746.762 604.831 450 605C154 605.169 -25 831 -25 831V-25Z" fill="url(#paint0_linear)"/>
            <defs>
                <linearGradient id="paint0_linear" x1="-2.00002" y1="6.00002" x2="1407" y2="757" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9E2DC1"/>
                    <stop offset="1" stopColor="#6718FA"/>
                </linearGradient>
            </defs>
        </svg>
    )
};

const RockSvg = () => {
    return (
        <svg className="preview__bg preview__bg-bottom" viewBox="0 0 1482 345" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M739 268C1035.76 267.831 1482 0 1482 0V345H0V22C0 22 443 268.169 739 268Z" fill="white"/>
        </svg>
    )
};

const DeliverySvg = () => {
    return (
        <svg className="preview__bg" viewBox="0 0 1441 760" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
            <path d="M-23 -52H1459V490C1459 490 1012.76 757.831 716 758C420 758.169 -40 512 -40 512L-23 -52Z" fill="url(#paint0_linear)"/>
            <defs>
                <linearGradient id="paint0_linear" x1="9.62915e-06" y1="-21" x2="1409" y2="730" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EFAE3C"/>
                    <stop offset="1" stopColor="#FDB73F"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

export {BarberSvg, SolarSvg, KoreSvg, RockSvg, DeliverySvg}