let time: number | null = null;

export function debounce(fn: Function, wait: number = 500, ...args: any[]) {
    return function (this: any) {
        if (time) clearTimeout(time);

        time = window.setTimeout(() => {
            fn.apply(this, args);
        }, wait);
    };
}
