import React, { ReactNode, useState, useEffect, createContext, useContext } from "react";

interface IDefaultContext {
    [key: string]: any;
    xs?: boolean;
    sm?: boolean;
    md?: boolean;
    lg?: boolean;
    xl?: boolean;
    xxl?: boolean;
    xxxl?: boolean;
}

interface IContext {
    [key: string]: boolean;
    xs: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
    xxl: boolean;
    xxxl: boolean;
}

interface IQueries {
    [key: string]: string;
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
    xxl: string;
    xxxl: string;
}

const defaultValue = {};

const BreakpointContext = createContext(defaultValue);

const BreakpointProvider = ({ children, queries }: { children: ReactNode; queries: IQueries }) => {
    const [queryMatch, setQueryMatch] = useState({});

    useEffect(() => {
        const mediaQueryLists: any = {};

        const keys = Object.keys(queries);
        let isAttached = false;

        const handleQueryListener = () => {
            const updatedMatches = keys.reduce((acc: IDefaultContext, media: string) => {
                acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);
                return acc;
            }, {});
            setQueryMatch(updatedMatches);
        };

        if (window && window.matchMedia) {
            const matches: IDefaultContext = {};
            keys.forEach((media) => {
                if (typeof queries[media] === "string") {
                    mediaQueryLists[media] = window.matchMedia(queries[media]);
                    matches[media] = mediaQueryLists[media].matches;
                } else {
                    matches[media] = false;
                }
            });
            setQueryMatch(matches);
            isAttached = true;
            keys.forEach((media) => {
                if (typeof queries[media] === "string") {
                    mediaQueryLists[media].addListener(handleQueryListener);
                }
            });
        }

        return () => {
            if (isAttached) {
                keys.forEach((media) => {
                    if (typeof queries[media] === "string") {
                        mediaQueryLists[media].removeListener(handleQueryListener);
                    }
                });
            }
        };
    }, [queries]);

    return <BreakpointContext.Provider value={queryMatch}>{children}</BreakpointContext.Provider>;
};

function useBreakpoint() {
    const context: IContext | any = useContext(BreakpointContext);
    if (context === defaultValue) {
        throw new Error("useBreakpoint must be used within BreakpointProvider");
    }

    const defaultContent = {
        xs: false,
        sm: false,
        md: false,
        lg: false,
        xl: false,
        xxl: false,
        xxxl: false,
    };

    if (Object.keys(context).length === 0) {
        return defaultContent;
    }

    if (window.innerWidth === 767) {
        context.sm = true; // bad code
    }

    return context;
}
export { useBreakpoint, BreakpointProvider };
