import React from "react";
import {ContactsUs} from "Templates/ContactsUs";
import {TextCase} from "Templates/TextContent";
import CaseTop from "Pages/Cases/View/Views/CaseTop";
import IProps from "./interface";
import {
    CaseOther,
    CaseImage,
    CaseBlackWhite,
    CaseVideo
} from "./Sections"


const CaseRockErp = ({_data, view}: IProps) => {
    const data = _data[view];

    return (
        <div className="single-case">
            <CaseTop _data={_data} view={view}/>
            <CaseImage img={data.previewImgFirst} classImg={data.previewImgClass} classNone={data.previewImgNone}/>
            <TextCase data={data.caseTextSecondary}/>
            <CaseBlackWhite data={data.previewImgWD}/>
            <TextCase data={data.caseTextExperience}/>
            <CaseVideo data={data.previewVideo} />
            <TextCase data={data.caseTextThird}/>
            <CaseOther data={data.caseSlide}/>
            <ContactsUs/>
        </div>
    );
};

export default CaseRockErp;