import React from "react";
import { Icon } from "Templates/Icon";

type Props = {
    rate?: number;
};

const Clutch = ({ rate = 5 }: Props) => {
    return (
        <a href="https://clutch.co/profile/rocque" target="_blank" rel="noopener noreferrer" className="clutch">
            <div className="clutch__logo">
                <Icon name="clutch" />
            </div>
            <div className="clutch__content">
                <div className={`rating rating_${rate}`}>
                    <span className="rating__star">
                        <Icon name="star" />
                        <Icon name="star-filled" />
                    </span>
                    <span className="rating__star">
                        <Icon name="star" />
                        <Icon name="star-filled" />
                    </span>
                    <span className="rating__star">
                        <i className="icon-star" />
                        <i className="icon-star-filled" />
                    </span>
                    <span className="rating__star">
                        <Icon name="star" />
                        <Icon name="star-filled" />
                    </span>
                    <span className="rating__star">
                        <Icon name="star" />
                        <Icon name="star-filled" />
                    </span>
                </div>
                <div className="clutch__text">3 Reviews</div>
            </div>
        </a>
    );
};

export default Clutch;
